export default {
  octaves: [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7],
  notes: ['C', 'C#/Db', 'D', 'D#/Eb', 'E', 'F', 'F#/Gb', 'G', 'G#/Ab', 'A', 'A#/Bb', 'B'],
  frequencies: [
    {
      note: 'C',
      octave: -2,
      midi: 0,
      frequency: 16.351,
    },
    {
      note: 'C#/Db',
      octave: -2,
      midi: 1,
      frequency: 17.324,
    },
    {
      note: 'D',
      octave: -2,
      midi: 2,
      frequency: 18.354,
    },
    {
      note: 'D#/Eb',
      octave: -2,
      midi: 3,
      frequency: 19.445,
    },
    {
      note: 'E',
      octave: -2,
      midi: 4,
      frequency: 20.601,
    },
    {
      note: 'F',
      octave: -2,
      midi: 5,
      frequency: 21.827,
    },
    {
      note: 'F#/Gb',
      octave: -2,
      midi: 6,
      frequency: 23.124,
    },
    {
      note: 'G',
      octave: -2,
      midi: 7,
      frequency: 24.499,
    },
    {
      note: 'G#/Ab',
      octave: -2,
      midi: 8,
      frequency: 25.956,
    },
    {
      note: 'A',
      octave: -2,
      midi: 9,
      frequency: 27.5,
    },
    {
      note: 'A#/Bb',
      octave: -2,
      midi: 10,
      frequency: 29.135,
    },
    {
      note: 'B',
      octave: -2,
      midi: 11,
      frequency: 30.868,
    },
    {
      note: 'C',
      octave: -1,
      midi: 12,
      frequency: 32.703,
    },
    {
      note: 'C#/Db',
      octave: -1,
      midi: 13,
      frequency: 34.648,
    },
    {
      note: 'D',
      octave: -1,
      midi: 14,
      frequency: 36.708,
    },
    {
      note: 'D#/Eb',
      octave: -1,
      midi: 15,
      frequency: 38.891,
    },
    {
      note: 'E',
      octave: -1,
      midi: 16,
      frequency: 41.203,
    },
    {
      note: 'F',
      octave: -1,
      midi: 17,
      frequency: 43.654,
    },
    {
      note: 'F#/Gb',
      octave: -1,
      midi: 18,
      frequency: 46.249,
    },
    {
      note: 'G',
      octave: -1,
      midi: 19,
      frequency: 48.999,
    },
    {
      note: 'G#/Ab',
      octave: -1,
      midi: 20,
      frequency: 51.913,
    },
    {
      note: 'A',
      octave: -1,
      midi: 21,
      frequency: 55,
    },
    {
      note: 'A#/Bb',
      octave: -1,
      midi: 22,
      frequency: 58.27,
    },
    {
      note: 'B',
      octave: -1,
      midi: 23,
      frequency: 61.735,
    },
    {
      note: 'C',
      octave: 0,
      midi: 24,
      frequency: 65.406,
    },
    {
      note: 'C#/Db',
      octave: 0,
      midi: 25,
      frequency: 69.296,
    },
    {
      note: 'D',
      octave: 0,
      midi: 26,
      frequency: 73.416,
    },
    {
      note: 'D#/Eb',
      octave: 0,
      midi: 27,
      frequency: 77.782,
    },
    {
      note: 'E',
      octave: 0,
      midi: 28,
      frequency: 82.407,
    },
    {
      note: 'F',
      octave: 0,
      midi: 29,
      frequency: 87.307,
    },
    {
      note: 'F#/Gb',
      octave: 0,
      midi: 30,
      frequency: 92.499,
    },
    {
      note: 'G',
      octave: 0,
      midi: 31,
      frequency: 97.999,
    },
    {
      note: 'G#/Ab',
      octave: 0,
      midi: 32,
      frequency: 103.826,
    },
    {
      note: 'A',
      octave: 0,
      midi: 33,
      frequency: 110,
    },
    {
      note: 'A#/Bb',
      octave: 0,
      midi: 34,
      frequency: 116.541,
    },
    {
      note: 'B',
      octave: 0,
      midi: 35,
      frequency: 123.471,
    },
    {
      note: 'C',
      octave: 1,
      midi: 36,
      frequency: 130.813,
    },
    {
      note: 'C#/Db',
      octave: 1,
      midi: 37,
      frequency: 138.591,
    },
    {
      note: 'D',
      octave: 1,
      midi: 38,
      frequency: 146.832,
    },
    {
      note: 'D#/Eb',
      octave: 1,
      midi: 39,
      frequency: 155.563,
    },
    {
      note: 'E',
      octave: 1,
      midi: 40,
      frequency: 164.814,
    },
    {
      note: 'F',
      octave: 1,
      midi: 41,
      frequency: 174.614,
    },
    {
      note: 'F#/Gb',
      octave: 1,
      midi: 42,
      frequency: 184.997,
    },
    {
      note: 'G',
      octave: 1,
      midi: 43,
      frequency: 195.998,
    },
    {
      note: 'G#/Ab',
      octave: 1,
      midi: 44,
      frequency: 207.652,
    },
    {
      note: 'A',
      octave: 1,
      midi: 45,
      frequency: 220,
    },
    {
      note: 'A#/Bb',
      octave: 1,
      midi: 46,
      frequency: 233.082,
    },
    {
      note: 'B',
      octave: 1,
      midi: 47,
      frequency: 246.942,
    },
    {
      note: 'C',
      octave: 2,
      midi: 48,
      frequency: 261.626,
    },
    {
      note: 'C#/Db',
      octave: 2,
      midi: 49,
      frequency: 277.183,
    },
    {
      note: 'D',
      octave: 2,
      midi: 50,
      frequency: 293.665,
    },
    {
      note: 'D#/Eb',
      octave: 2,
      midi: 51,
      frequency: 311.127,
    },
    {
      note: 'E',
      octave: 2,
      midi: 52,
      frequency: 329.628,
    },
    {
      note: 'F',
      octave: 2,
      midi: 53,
      frequency: 349.228,
    },
    {
      note: 'F#/Gb',
      octave: 2,
      midi: 54,
      frequency: 369.994,
    },
    {
      note: 'G',
      octave: 2,
      midi: 55,
      frequency: 391.995,
    },
    {
      note: 'G#/Ab',
      octave: 2,
      midi: 56,
      frequency: 415.305,
    },
    {
      note: 'A',
      octave: 2,
      midi: 57,
      frequency: 440,
    },
    {
      note: 'A#/Bb',
      octave: 2,
      midi: 58,
      frequency: 466.164,
    },
    {
      note: 'B',
      octave: 2,
      midi: 59,
      frequency: 493.883,
    },
    {
      note: 'C',
      octave: 3,
      midi: 60,
      frequency: 523.251,
    },
    {
      note: 'C#/Db',
      octave: 3,
      midi: 61,
      frequency: 554.365,
    },
    {
      note: 'D',
      octave: 3,
      midi: 62,
      frequency: 587.33,
    },
    {
      note: 'D#/Eb',
      octave: 3,
      midi: 63,
      frequency: 622.254,
    },
    {
      note: 'E',
      octave: 3,
      midi: 64,
      frequency: 659.255,
    },
    {
      note: 'F',
      octave: 3,
      midi: 65,
      frequency: 698.456,
    },
    {
      note: 'F#/Gb',
      octave: 3,
      midi: 66,
      frequency: 739.989,
    },
    {
      note: 'G',
      octave: 3,
      midi: 67,
      frequency: 783.991,
    },
    {
      note: 'G#/Ab',
      octave: 3,
      midi: 68,
      frequency: 830.609,
    },
    {
      note: 'A',
      octave: 3,
      midi: 69,
      frequency: 880,
    },
    {
      note: 'A#/Bb',
      octave: 3,
      midi: 70,
      frequency: 932.328,
    },
    {
      note: 'B',
      octave: 3,
      midi: 71,
      frequency: 987.767,
    },
    {
      note: 'C',
      octave: 4,
      midi: 72,
      frequency: 1046.502,
    },
    {
      note: 'C#/Db',
      octave: 4,
      midi: 73,
      frequency: 1108.731,
    },
    {
      note: 'D',
      octave: 4,
      midi: 74,
      frequency: 1174.659,
    },
    {
      note: 'D#/Eb',
      octave: 4,
      midi: 75,
      frequency: 1244.508,
    },
    {
      note: 'E',
      octave: 4,
      midi: 76,
      frequency: 1318.51,
    },
    {
      note: 'F',
      octave: 4,
      midi: 77,
      frequency: 1396.913,
    },
    {
      note: 'F#/Gb',
      octave: 4,
      midi: 78,
      frequency: 1479.978,
    },
    {
      note: 'G',
      octave: 4,
      midi: 79,
      frequency: 1567.982,
    },
    {
      note: 'G#/Ab',
      octave: 4,
      midi: 80,
      frequency: 1661.219,
    },
    {
      note: 'A',
      octave: 4,
      midi: 81,
      frequency: 1760,
    },
    {
      note: 'A#/Bb',
      octave: 4,
      midi: 82,
      frequency: 1864.655,
    },
    {
      note: 'B',
      octave: 4,
      midi: 83,
      frequency: 1975.533,
    },
    {
      note: 'C',
      octave: 5,
      midi: 84,
      frequency: 2093.005,
    },
    {
      note: 'C#/Db',
      octave: 5,
      midi: 85,
      frequency: 2217.461,
    },
    {
      note: 'D',
      octave: 5,
      midi: 86,
      frequency: 2349.318,
    },
    {
      note: 'D#/Eb',
      octave: 5,
      midi: 87,
      frequency: 2489.016,
    },
    {
      note: 'E',
      octave: 5,
      midi: 88,
      frequency: 2637.021,
    },
    {
      note: 'F',
      octave: 5,
      midi: 89,
      frequency: 2793.826,
    },
    {
      note: 'F#/Gb',
      octave: 5,
      midi: 90,
      frequency: 2959.955,
    },
    {
      note: 'G',
      octave: 5,
      midi: 91,
      frequency: 3135.964,
    },
    {
      note: 'G#/Ab',
      octave: 5,
      midi: 92,
      frequency: 3322.438,
    },
    {
      note: 'A',
      octave: 5,
      midi: 93,
      frequency: 3520,
    },
    {
      note: 'A#/Bb',
      octave: 5,
      midi: 94,
      frequency: 3729.31,
    },
    {
      note: 'B',
      octave: 5,
      midi: 95,
      frequency: 3951.066,
    },
    {
      note: 'C',
      octave: 6,
      midi: 96,
      frequency: 4186.009,
    },
    {
      note: 'C#/Db',
      octave: 6,
      midi: 97,
      frequency: 4434.922,
    },
    {
      note: 'D',
      octave: 6,
      midi: 98,
      frequency: 4698.636,
    },
    {
      note: 'D#/Eb',
      octave: 6,
      midi: 99,
      frequency: 4978.032,
    },
    {
      note: 'E',
      octave: 6,
      midi: 100,
      frequency: 5274.042,
    },
    {
      note: 'F',
      octave: 6,
      midi: 101,
      frequency: 5587.652,
    },
    {
      note: 'F#/Gb',
      octave: 6,
      midi: 102,
      frequency: 5919.91,
    },
    {
      note: 'G',
      octave: 6,
      midi: 103,
      frequency: 6271.928,
    },
    {
      note: 'G#/Ab',
      octave: 6,
      midi: 104,
      frequency: 6644.876,
    },
    {
      note: 'A',
      octave: 6,
      midi: 105,
      frequency: 7040,
    },
    {
      note: 'A#/Bb',
      octave: 6,
      midi: 106,
      frequency: 7458.62,
    },
    {
      note: 'B',
      octave: 6,
      midi: 107,
      frequency: 7902.132,
    },
    {
      note: 'C',
      octave: 7,
      midi: 108,
      frequency: 8372.018,
    },
    {
      note: 'C#/Db',
      octave: 7,
      midi: 109,
      frequency: 8869.844,
    },
    {
      note: 'D',
      octave: 7,
      midi: 110,
      frequency: 9397.272,
    },
    {
      note: 'D#/Eb',
      octave: 7,
      midi: 111,
      frequency: 9956.064,
    },
    {
      note: 'E',
      octave: 7,
      midi: 112,
      frequency: 10548.084,
    },
    {
      note: 'F',
      octave: 7,
      midi: 113,
      frequency: 11175.304,
    },
    {
      note: 'F#/Gb',
      octave: 7,
      midi: 114,
      frequency: 11839.82,
    },
    {
      note: 'G',
      octave: 7,
      midi: 115,
      frequency: 12543.856,
    },
    {
      note: 'G#/Ab',
      octave: 7,
      midi: 116,
      frequency: 13289.752,
    },
    {
      note: 'A',
      octave: 7,
      midi: 117,
      frequency: 14080,
    },
    {
      note: 'A#/Bb',
      octave: 7,
      midi: 118,
      frequency: 14917.24,
    },
    {
      note: 'B',
      octave: 7,
      midi: 119,
      frequency: 15804.264,
    },
  ],
};
