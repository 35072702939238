let names = {
  adjectives: [
    'adorable',
    'adventurous',
    'aggressive',
    'agreeable',
    'alert',
    'alive',
    'amused',
    'angry',
    'annoyed',
    'annoying',
    'anxious',
    'arrogant',
    'ashamed',
    'attractive',
    'average',
    'awful',
    'bad',
    'beautiful',
    'better',
    'bewildered',
    'black',
    'bloody',
    'blue',
    'blushing',
    'bored',
    'brainy',
    'brave',
    'breakable',
    'bright',
    'busy',
    'calm',
    'careful',
    'cautious',
    'charming',
    'cheerful',
    'clean',
    'clear',
    'clever',
    'cloudy',
    'clumsy',
    'colorful',
    'combative',
    'comfortable',
    'concerned',
    'condemned',
    'confused',
    'cooperative',
    'courageous',
    'crazy',
    'creepy',
    'crowded',
    'cruel',
    'curious',
    'cute',
    'dangerous',
    'dark',
    'dead',
    'defeated',
    'defiant',
    'delightful',
    'depressed',
    'determined',
    'different',
    'difficult',
    'disgusted',
    'distinct',
    'disturbed',
    'dizzy',
    'doubtful',
    'drab',
    'dull',
    'eager',
    'easy',
    'elated',
    'elegant',
    'embarrassed',
    'enchanting',
    'encouraging',
    'energetic',
    'enthusiastic',
    'envious',
    'evil',
    'excited',
    'expensive',
    'exuberant',
    'fair',
    'faithful',
    'famous',
    'fancy',
    'fantastic',
    'fierce',
    'filthy',
    'fine',
    'foolish',
    'fragile',
    'frail',
    'frantic',
    'friendly',
    'frightened',
    'funny',
    'gentle',
    'gifted',
    'glamorous',
    'gleaming',
    'glorious',
    'good',
    'gorgeous',
    'graceful',
    'grieving',
    'grotesque',
    'grumpy',
    'handsome',
    'happy',
    'healthy',
    'helpful',
    'helpless',
    'hilarious',
    'homeless',
    'homely',
    'horrible',
    'hungry',
    'hurt',
    'ill',
    'important',
    'impossible',
    'inexpensive',
    'innocent',
    'inquisitive',
    'itchy',
    'jealous',
    'jittery',
    'jolly',
    'joyous',
    'kind',
    'lazy',
    'light',
    'lively',
    'lonely',
    'long',
    'lovely',
    'lucky',
    'magnificent',
    'misty',
    'modern',
    'motionless',
    'muddy',
    'mushy',
    'mysterious',
    'nasty',
    'naughty',
    'nervous',
    'nice',
    'nutty',
    'obedient',
    'obnoxious',
    'odd',
    'open',
    'outrageous',
    'outstanding',
    'panicky',
    'perfect',
    'plain',
    'pleasant',
    'poised',
    'poor',
    'powerful',
    'precious',
    'prickly',
    'proud',
    'puzzled',
    'quaint',
    'real',
    'relieved',
    'repulsive',
    'rich',
    'scary',
    'selfish',
    'shiny',
    'shy',
    'silly',
    'sleepy',
    'smiling',
    'smoggy',
    'sore',
    'sparkling',
    'splendid',
    'spotless',
    'stormy',
    'strange',
    'stupid',
    'successful',
    'super',
    'talented',
    'tame',
    'tender',
    'tense',
    'terrible',
    'testy',
    'thankful',
    'thoughtful',
    'thoughtless',
    'tired',
    'tough',
    'troubled',
    'ugliest',
    'ugly',
    'uninterested',
    'unsightly',
    'unusual',
    'upset',
    'uptight',
    'vast',
    'victorious',
    'vivacious',
    'wandering',
    'weary',
    'wicked',
    'wild',
    'witty',
    'worrisome',
    'worried',
    'wrong',
    'zany',
    'zealous',
  ],
  nouns: [
    'frogs',
    'bath',
    'wren',
    'tax',
    'tin',
    'vest',
    'hobbies',
    'spoon',
    'carriage',
    'bushes',
    'ducks',
    'zebra',
    'grape',
    'riddle',
    'lunch',
    'hands',
    'country',
    'oatmeal',
    'income',
    'trade',
    'spiders',
    'power',
    'bite',
    'needle',
    'mask',
    'flower',
    'eggnog',
    'flowers',
    'society',
    'exchange',
    'curtain',
    'business',
    'appliance',
    'mint',
    'competition',
    'offer',
    'authority',
    'control',
    'vegetable',
    'discovery',
    'base',
    'top',
    'oven',
    'smell',
    'wrench',
    'friends',
    'window',
    'rat',
    'magic',
    'badge',
    'milk',
    'servant',
    'volcano',
    'weight',
    'coil',
    'dust',
    'fowl',
    'cherries',
    'orange',
    'yard',
    'desire',
    'payment',
    'jeans',
    'river',
    'slope',
    'grandfather',
    'ocean',
    'quarter',
    'toothpaste',
    'zoo',
    'pen',
    'motion',
    'toes',
    'mailbox',
    'worm',
    'fork',
    'reward',
    'hour',
    'crib',
    'writing',
    'crow',
    'ground',
    'yoke',
    'jump',
    'liquid',
    'babies',
    'visitor',
    'texture',
    'volleyball',
    'hate',
    'laborer',
    'route',
    'rose',
    'thought',
    'tail',
    'shoes',
    'steel',
    'lip',
    'lumber',
    'trip',
    'rake',
    'road',
    'year',
    'jewel',
    'dolls',
    'fear',
    'shame',
    'mice',
    'stream',
    'lock',
    'store',
    'run',
    'eggs',
    'sweater',
    'boy',
    'history',
    'distribution',
    'tank',
    'tooth',
    'knife',
    'dog',
    'reading',
    'title',
    'division',
    'partner',
    'silk',
    'example',
    'notebook',
    'back',
    'minute',
    'thumb',
    'party',
    'sofa',
    'locket',
    'fall',
    'turn',
    'harbor',
    'scarf',
    'tiger',
    'bulb',
    'machine',
    'crayon',
    'bear',
    'ray',
    'friction',
    'grade',
    'fog',
    'mind',
    'quiver',
    'gold',
    'seashore',
    'spot',
    'quill',
    'drain',
    'chess',
    'belief',
    'education',
    'request',
    'ticket',
    'blood',
    'face',
    'unit',
    'language',
    'debt',
    'balance',
    'mark',
    'roof',
    'giants',
    'stick',
    'chalk',
    'creature',
    'tent',
    'page',
    'punishment',
    'side',
    'fly',
    'anger',
    'account',
    'pickle',
    'drawer',
    'arithmetic',
    'friend',
    'haircut',
    'monkey',
    'deer',
    'structure',
    'pail',
    'button',
    'guitar',
    'letter',
    'mountain',
    'theory',
    'playground',
    'sleep',
    'step',
    'walk',
    'action',
    'pull',
    'duck',
    'jelly',
    'beef',
    'screw',
    'dress',
    'profit',
    'join',
    'town',
    'start',
    'beds',
    'match',
    'writer',
    'earthquake',
    'maid',
    'talk',
    'son',
    'land',
    'force',
    'cook',
    'pipe',
    'calendar',
    'flavor',
    'reason',
    'group',
    'planes',
    'form',
    'hose',
    'quicksand',
  ],
  blues: [
    'Baby Tate',
    'Backwards Sam Firk',
    'Barbecue Bob',
    "Barkin' Bill Smith",
    'Barrelhouse Chuck',
    'B.B. King',
    'Big Bill Broonzy',
    'Arthur "Big Boy" Crudup',
    'Willie "Big Eyes" Smith',
    'Big Joe Duskin',
    'Big Joe Turner',
    'Big Maceo Merriweather',
    'Big Mama Thornton',
    'Johnny "Big Moose" Walker',
    'Otis "Big Smokey" Smothers',
    'Big Walter Horton',
    'Golden "Big" Wheeler',
    'Black Ace',
    'Blind Blake',
    'Blind Boy Fuller',
    'Blind Boy Grunt',
    'Blind Gary Davis',
    'Blind Joe Reynolds',
    'Blind Lemon Jefferson',
    'Blind Mississippi Morris',
    'Blind Willie Johnson',
    'Blind Willie McTell',
    'Kenny "Blues Boss" Wayne',
    'Bo Carter',
    'Bo Diddley',
    'Bob Log III',
    'Boogie Bill Webb',
    'Boogie Woogie Red',
    'Brownie McGhee',
    'Buddy Guy',
    'Bukka White',
    'Bull City Red',
    'Bumble Bee Slim',
    'Buster Benton',
    'Buster Pickens',
    'Catfish Keith',
    'Champion Jack Dupree',
    'Detroit Gary Wiggins',
    'Eddie "Cleanhead" Vinson',
    'Cow Cow Davenport',
    'Cripple Clarence Lofton',
    'Curley Weaver',
    "Drive 'Em Down",
    "Fiddlin' Joe Martin",
    'Furry Lewis',
    'Gary B.B. Coleman',
    'Clarence "Gatemouth" Brown',
    'Georgia Tom',
    'Eddie "Guitar" Burns',
    'Guitar Slim',
    'Harmonica Shah',
    'H-Bomb Ferguson',
    'Hip Linkchain',
    'Hollywood Fats',
    'David Honeyboy Edwards',
    'Hound Dog Taylor',
    "Howlin' Wolf",
    'Ironing Board Sam',
    'Ivory Joe Hunter',
    'Jaybird Coleman',
    'Jazz Gillum',
    'Jelly Roll Morton',
    'Johnny Drummer',
    'Junior Wells',
    'Kansas Joe McCoy',
    "Keb' Mo'",
    'Koko Taylor',
    'Laughing Charley',
    'Lazy Lester',
    'Lead Belly',
    "Lightnin' Hopkins",
    "Lil' Ed Williams",
    'Little Brother Montgomery',
    'Little Freddie King',
    'Little Hatch',
    'Little Mack Simmons',
    'Little Milton',
    'Little Smokey Smothers',
    'Little Sonny',
    'Tony "Little Sun" Glover',
    'Little Walter',
    'Little Willie Littlefield',
    'Lonesome Sundown',
    'Louisiana Red',
    'Lovie Austin',
    'Ma Rainey',
    'Magic Sam',
    'Magic Slim',
    'Mamie Smith',
    'Mance Lipscomb',
    'Maxwell Street Jimmy Davis',
    'Memphis Minnie',
    'Memphis Slim',
    'Mighty Joe Young',
    'Mississippi John Hurt',
    'Mississippi Fred McDowell',
    'George "Mojo" Buford',
    'Mr. Blues',
    'Muddy Waters',
    'Papa Charlie Jackson',
    'Papa Charlie McCoy',
    'Peetie Wheatstraw',
    'Pee Wee Crayton',
    'Leon "Pee Wee" Whittaker',
    'Peg Leg Howell',
    'Peg Leg Sam',
    'Piano Red',
    'Huey "Piano" Smith',
    'Pinetop Perkins',
    'Pinetop Smith',
    'Pink Anderson',
    'Pops Staples',
    'Popa Chubby',
    'Rabbit Brown',
    "Ramblin' Thomas",
    'Robert Nighthawk',
    "Rockin' Sidney",
    'Scrapper Blackwell',
    'Seasick Steve',
    'Shakey Jake Harris',
    'Sippie Wallace',
    'Skip James',
    'Sleepy John Estes',
    'Slim Harpo',
    'Slowhand',
    'Smokey Hogg',
    'Smokey Wilson',
    "Smokin' Joe Bonamassa",
    'Smoky Babe',
    'Dave "Snaker" Ray',
    'Soko Richardson',
    'Son House',
    'Sonny Terry',
    'Sonny Boy Williamson I',
    'Sonny Boy Williamson II',
    '"Spider" John Koerner',
    'St. Louis Jimmy Oden',
    'Sugar Ray Norcia',
    'Sunnyland Slim',
    'Tab Smith',
    'Tabby Thomas',
    'Taj Mahal',
    'Tampa Red',
    'T-Bone Walker',
    'T-Model Ford',
    'Alger "Texas" Alexander',
    'Tommy Tucker',
    'U.P. Wilson',
    'Washboard Sam',
    'Washboard Willie',
    'Watermelon Slim',
    "Whistlin' Alex Moore",
    'Moses "Whispering" Smith',
    'Paul "Wine" Jones',
  ],
  weird: [
    'Abaya',
    'Abomasum',
    'Absquatulate',
    'Adscititious',
    'Afreet',
    'Albertopolis',
    'Alcazar',
    'Amphibology',
    'Amphisbaena',
    'Anfractuous',
    'Anguilliform',
    'Apoptosis',
    'Apple',
    'Argle',
    'Argus',
    'Argute',
    'Ariel',
    'Aristotle',
    'Aspergillum',
    'Astrobleme',
    'Attic',
    'Autotomy',
    'Badmash',
    'Bandoline',
    'Bardolatry',
    'Barmecide',
    'Barn',
    'Bashment',
    'Bawbee',
    'Benthos',
    'Bergschrund',
    'Bezoar',
    'Bibliopole',
    'Bichon',
    'Bilboes',
    'Bindlestiff',
    'Bingle',
    'Blatherskite',
    'Bleeding',
    'Blind',
    'Bobsy',
    'Boffola',
    'Boilover',
    'Borborygmus',
    'Breatharian',
    'Brobdingnagian',
    'Bruxism',
    'Bumbo',
    'Burnsides',
    'Cacoethes',
    'Callipygian',
    'Callithumpian',
    'Camisado',
    'Canorous',
    'Cantillate',
    'Carphology',
    'Catoptromancy',
    'Cereology',
    'Cerulean',
    'Chad',
    'Chalkdown',
    'Chanticleer',
    'Chiliad',
    'Chump',
    'Claggy',
    'Clepsydra',
    'Colporteur',
    'Comess',
    'Commensalism',
    'Comminatory',
    'Concinnity',
    'Congius',
    'Conniption',
    'Constellate',
    'Coprolalia',
    'Coriaceous',
    'Couthy',
    'Criticaster',
    'Crore',
    'Crottle',
    'Croze',
    'Cryptozoology',
    'Cudbear',
    'Cupreous',
    'Cyanic',
    'Cybersquatting',
    'Dariole',
    'Deasil',
    'Decubitus',
    'Deedy',
    'Defervescence',
    'Deglutition',
    'Degust',
    'Deipnosophist',
    'Deracinate',
    'Deterge',
    'Didi',
    'Digerati',
    'Dight',
    'Discobolus',
    'Disembogue',
    'Disenthral',
    'Divagate',
    'Divaricate',
    'Donkey',
    'Donkeyman',
    'Doryphore',
    'Dotish',
    'Douceur',
    'Draff',
    'Dragoman',
    'Dumbsize',
    'Dwaal',
    'Ecdysiast',
    'Edacious',
    'Effable',
    'Emacity',
    'Emmetropia',
    'Empasm',
    'Ensorcell',
    'Entomophagy',
    'Erf',
    'Ergometer',
    'Erubescent',
    'E',
    'Etui',
    'Eucatastrophe',
    'Eurhythmic',
    'Eviternity',
    'Exequies',
    'Exsanguine',
    'Extramundane',
    'Eye',
    'Eyewater',
    'Famulus',
    'Fankle',
    'Fipple',
    'Flatline',
    'Flews',
    'Floccinaucinihilipilification',
    'Flocculent',
    'Force',
    'Forehanded',
    'Frondeur',
    'Fugacious',
    'Funambulist',
    'Furuncle',
    'Fuscous',
    'Futhark',
    'Futz',
    'Gaberlunzie',
    'Gaita',
    'Galligaskins',
    'Gallus',
    'Gasconade',
    'Glabrous',
    'Glaikit',
    'Gnathic',
    'Gobemouche',
    'Goodfella',
    'Guddle',
    'Habile',
    'Hallux',
    'Haruspex',
    'Higgler',
    'Hinky',
    'Hoddy',
    'Hodiernal',
    'Hoggin',
    'Hongi',
    'Howff',
    'Humdudgeon',
    'Hunt',
    'Hwyl',
    'Illywhacker',
    'Incrassate',
    'Incunabula',
    'Ingurgitate',
    'Inspissate',
    'Inunct',
    'Jumbuck',
    'Jumentous',
    'Jungli',
    'Karateka',
    'Keek',
    'Kenspeckle',
    'Kinnikinnick',
    'Kylie',
    'Labarum',
    'Lablab',
    'Lactarium',
    'Liripipe',
    'Loblolly',
    'Lobola',
    'Logomachy',
    'Lollygag',
    'Luculent',
    'Lycanthropy',
    'Macushla',
    'Mallam',
    'Mamaguy',
    'Martlet',
    'Mazel',
    'Meacock',
    'Merkin',
    'Merrythought',
    'Mim',
    'Mimsy',
    'Minacious',
    'Minibeast',
    'Misogamy',
    'Mistigris',
    'Mixologist',
    'Mollitious',
    'Momism',
    'Monkey',
    'Monorchid',
    'Moonraker',
    'Mouse',
    'Mudlark',
    'Muktuk',
    'Mumpsimus',
    'Nacarat',
    'Nagware',
    'Nainsook',
    'Natation',
    'Nesh',
    'Netizen',
    'Noctambulist',
    'Noyade',
    'Nugacity',
    'Nympholepsy',
    'Obnubilate',
    'Ogdoad',
    'Omophagy',
    'Omphalos',
    'Onolatry',
    'O',
    'Operose',
    'Opsimath',
    'Orectic',
    'Orrery',
    'Ortanique',
    'Otalgia',
    'Oxter',
    'Paludal',
    'Pantagruelian',
    'Panurgic',
    'Parapente',
    'Paraph',
    'Patulous',
    'Pavonine',
    'Pedicular',
    'Peely',
    'Peever',
    'Periapt',
    'Petcock',
    'Peterman',
    'Pettitoes',
    'Piacular',
    'Pilgarlic',
    'Pinguid',
    'Piscatorial',
    'Pleurodynia',
    'Plew',
    'Pogey',
    'Pollex',
    'Pooter',
    'Portolan',
    'Posology',
    'Possident',
    'Pother',
    'Pre',
    'Presenteeism',
    'Previse',
    'Probang',
    'Prosopagnosia',
    'Puddle',
    'Puddysticks',
    'Pyknic',
    'Pyroclastic',
    'Ragtop',
    'Ratite',
    'Rawky',
    'Razzia',
    'Rebirthing',
    'Resurrection',
    'Retiform',
    'Rhinoplasty',
    'Rubiginous',
    'Rubricate',
    'Rude',
    'Rug',
    'Rumpot',
    'Sangoma',
    'Sarmie',
    'Saucier',
    'Saudade',
    'Scofflaw',
    'Screenager',
    'Scrippage',
    'Selkie',
    'Serac',
    'Sesquipedalian',
    'Shallop',
    'Shamal',
    'Shavetail',
    'Shippon',
    'Shofar',
    'Skanky',
    'Skelf',
    'Skimmington',
    'Skycap',
    'Snakebitten',
    'Snollygoster',
    'Sockdolager',
    'Solander',
    'Soucouyant',
    'Soul',
    'Soul',
    'Spaghettification',
    'Spitchcock',
    'Splanchnic',
    'Spurrier',
    'Stercoraceous',
    'Sternutator',
    'Stiction',
    'Strappado',
    'Strigil',
    'Struthious',
    'Studmuffin',
    'Stylite',
    'Subfusc',
    'Submontane',
    'Succuss',
    'Sudd',
    'Suedehead',
    'Sun',
    'Superbious',
    'Superette',
    'Taniwha',
    'Tappen',
    'Tellurian',
    'Testudo',
    'Thalassic',
    'Thaumatrope',
    'Thirstland',
    'Thrutch',
    'Thurifer',
    'Tiffin',
    'Tigon',
    'Tokoloshe',
    'Toplofty',
    'Transpicuous',
    'Triskaidekaphobia',
    'Triskelion',
    'Tsantsa',
    'Turbary',
    'Ulu',
    'Umbriferous',
    'Uncinate',
    'Uniped',
    'Uroboros',
    'Ustad',
    'Vagarious',
    'Velleity',
    'Verjuice',
    'Vicinal',
    'Vidiot',
    'Vomitous',
    'Wabbit',
    'Waitron',
    'Wakeboarding',
    'Wayzgoose',
    'Winebibber',
    'Wish',
    'Wittol',
    'Woopie',
    'Wowser',
    'Xenology',
    'Ylem',
    'Zetetic',
    'Zoolatry',
    'Zopissa',
    'Zorro',
    'Zyrian',
  ],
};

const generate = (blues = false, weird = false) => {
  if (blues) {
    let idx = Math.floor(Math.random() * names.blues.length);
    return names.blues[idx];
  }

  if (weird) {
    let idx = Math.floor(Math.random() * names.weird.length);
    return names.weird[idx];
  }

  let nounIdx = Math.floor(Math.random() * names.nouns.length);
  let adjIdx = Math.floor(Math.random() * names.adjectives.length);
  let adj = names.adjectives[adjIdx];
  let noun = names.nouns[nounIdx];
  adj = adj.charAt(0).toUpperCase() + adj.slice(1);
  noun = noun.charAt(0).toUpperCase() + noun.slice(1);
  return adj + ' ' + noun;
};

export default {
  generate,
};
